<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.create_stamp") }}
      <v-spacer></v-spacer>
      <template v-if="info.email_goods_receipt">
        <a
          class="mr-1 v-btn v-btn--outlined theme--light v-size--small default"
          :href="templateLink"
          target="_blank"
        >
          <v-icon>mdi-download</v-icon>
          {{ $t("labels.sample_file") }}
        </a>

        <v-btn
          color="primary"
          small
          outlined
          class="mr-1"
          @click="$refs.inputUploadFile.click()"
        >
          <v-icon>mdi-upload</v-icon>
          {{ $t("labels.upload") }}
        </v-btn>
        <input
          type="file"
          ref="inputUploadFile"
          accept=".xlsx"
          @change="onInputFileChange"
          class="d-none"
        />

        <v-btn
          color="success"
          small
          outlined
          class="mr-1"
          @click="create"
          :disabled="items.length < 1"
        >
          {{ $t("labels.confirm") }}
        </v-btn>
      </template>
      <v-btn color="error" small outlined @click="closeDialog">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <div v-if="info.email_goods_receipt" class="font-italic warning--text">
        Tem sẽ được gửi về Email: <b>{{ info.email_goods_receipt }}</b>
      </div>
      <div v-else class="font-italic error--text">
        Chưa có email nhận thông tin NHẬP KHO
      </div>
      <v-simple-table
        fixed-header
        height="calc(100vh - 245px)"
        class="table-padding-2-no-top"
      >
        <template v-slot:default>
          <thead class="v-data-table-header" v-if="items && items.length > 0">
            <tr>
              <th>Tên sản phẩm</th>
              <th>Mã sản phẩm</th>
              <th>Cấp ngăn cháy</th>
              <th>Kích thước</th>
              <th>Số giấy chứng nhận</th>
              <th>Ngày cấp</th>
              <th>Đơn vị cấp</th>
              <th>Dự án</th>
              <th>Địa chỉ</th>
              <th>Số tem cấp</th>
              <th>Ngày sản xuất</th>
            </tr>
          </thead>
          <tbody v-if="items && items.length > 0">
            <tr v-for="(item, key) in items" :key="`re_${key}`">
              <td>
                <span v-if="!item.product_name" class="error--text font-italic">
                  {{ $t("labels.required") }}</span
                >
                <span v-else>{{ item.product_name }}</span>
              </td>
              <td>
                <span v-if="!item.product_code" class="error--text font-italic">
                  {{ $t("labels.required") }}</span
                >
                <span v-else>{{ item.product_code }}</span>
              </td>
              <td>
                <span
                  v-if="!item.fire_prevention_level"
                  class="error--text font-italic"
                >
                  {{ $t("labels.required") }}</span
                >
                <span v-else>{{ item.fire_prevention_level }}</span>
              </td>
              <td>
                <span v-if="!item.product_size" class="error--text font-italic">
                  {{ $t("labels.required") }}</span
                >
                <span v-else>{{ item.product_size }}</span>
              </td>
              <td>
                <span
                  v-if="!item.certificate_number"
                  class="error--text font-italic"
                >
                  {{ $t("labels.required") }}</span
                >
                <span v-else>{{ item.certificate_number }}</span>
              </td>
              <td>
                <span
                  v-if="!item.certificate_date"
                  class="error--text font-italic"
                >
                  {{ $t("labels.required") }}</span
                >
                <span v-else>{{ item.certificate_date }}</span>
              </td>
              <td>
                <span
                  v-if="!item.certificate_company"
                  class="error--text font-italic"
                >
                  {{ $t("labels.required") }}</span
                >
                <span v-else>{{ item.certificate_company }}</span>
              </td>
              <td>
                <span v-if="!item.project" class="error--text font-italic">
                  {{ $t("labels.required") }}</span
                >
                <span v-else>{{ item.project }}</span>
              </td>
              <td>
                <span v-if="!item.address" class="error--text font-italic">
                  {{ $t("labels.required") }}</span
                >
                <span v-else>{{ item.address }}</span>
              </td>
              <td>
                <span v-if="!item.stamp_number" class="error--text font-italic">
                  {{ $t("labels.required") }}</span
                >
                <span v-else>{{ item.stamp_number }}</span>
              </td>
              <td>
                <span
                  v-if="!item.manufacture_date"
                  class="error--text font-italic"
                >
                  {{ $t("labels.required") }}</span
                >
                <span v-else>{{ item.manufacture_date }}</span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import { read, utils } from "xlsx";

export default {
  name: "GenerateStamp",
  components: {},
  props: {},
  data: () => ({
    items: [],
    isLoading: false,
    isChanged: false,
    info: {},
  }),
  computed: {
    templateLink() {
      return `${process.env.VUE_APP_FILE_CDN_URL}/templates/template_nsca.xlsx`;
    },
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      httpClient.post("/company-info").then(({ data }) => {
        this.info = { ...data };
      });
    },
    async downloadExcel() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      const filename = "ds-hang-hoa-cap-tem.xlsx";

      try {
        await this.downloadExcelFile(
          "/goods-list-export-for-generate-stamp",
          {},
          filename
        );
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },

    async onInputFileChange(e) {
      const mappingFields = {
        "Tên sản phẩm": "product_name",
        "Mã sản phẩm": "product_code",
        "Cấp ngăn cháy": "fire_prevention_level",
        "Kích thước": "product_size",
        "Số giấy chứng nhận": "certificate_number",
        "Ngày cấp": "certificate_date",
        "Đơn vị cấp": "certificate_company",
        "Dự án": "project",
        "Địa chỉ": "address",
        "Số tem cấp": "stamp_number",
        "Ngày sản xuất": "manufacture_date",
      };
      const files = e.target.files,
        f = files[0];
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = read(data, { type: "array" });
        let sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const values = utils.sheet_to_json(worksheet);
        const items = [...values].map((v) => {
          const item = {};
          Object.keys(v).forEach((i) => {
            item[mappingFields[i]] = `${v[i]}`.trim();
          });
          return item;
        });
        this.items = [...items];
      };
      await reader.readAsArrayBuffer(f);
    },

    isValidData() {
      const items = [...this.items];
      const emptyQuantity = items
        .filter(
          (item) =>
            !item.product_name ||
            !item.product_code ||
            !item.product_code ||
            !item.fire_prevention_level ||
            !item.product_size ||
            !item.certificate_number ||
            !item.certificate_date ||
            !item.certificate_company ||
            !item.project ||
            !item.address ||
            !item.stamp_number ||
            item.stamp_number == "0" ||
            !item.manufacture_date
        )
        .map((item) => item.product_code);

      if (emptyQuantity && emptyQuantity.length > 0) {
        this.$vToastify.error(
          this.$t("messages.data_goods_missing", {
            barcodes: emptyQuantity.join("; "),
          })
        );
        return false;
      }

      const duplicateBarcodes = [];
      const noDuplicateBarcodes = [];
      items.forEach((i) => {
        const nd = [...noDuplicateBarcodes].find((n) => n === i.product_code);
        if (!nd) {
          noDuplicateBarcodes.push(i.product_code);
        } else {
          duplicateBarcodes.push(i.product_code);
        }
      });
      if (duplicateBarcodes.length > 0) {
        this.$vToastify.error(
          this.$t("messages.duplicate_barcodes", {
            barcodes: duplicateBarcodes.join("; "),
          })
        );
        return false;
      }

      return true;
    },

    closeDialog() {
      if (this.isChanged) {
        this.$emit("refreshData", true);
      }
      this.$emit("close", true);
    },

    async create() {
      const isValid = this.isValidData();
      if (!isValid) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/nsca-stamp-create", {
          items: this.items,
        });
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.create_success"));
        this.items = [];
        this.isChanged = true;
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
